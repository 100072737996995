// styles that affect or directly and explicitly extend native elements (example: button--link) go here
html
body
    padding 0px
    margin 0px
    position relative
    height 100%
    width 100%

body
    overflow hidden

button
    cursor pointer

.button--plain
    border none
    background none

.button--link
    border none
    background none
    color get(colors, 'blue--link')
    text-decoration underline
    cursor pointer

.left-panel a
    font-size 12px
    &.btn-small-padding
        padding 0 1rem !important

.s6
    width 50%
    float left
