// styles that do not have obvious categories go here

//materialize overide
.card-action {
  padding: 10px 20px !important;
}

.subsidiary-footer
  margin 0 10em !important
  line-height 19px !important
  max-width 100% !important

//ESRI POPUP OVERRIDE
.esriPopup {
  font-family: "Roboto" !important;
  border-radius: 0px !important;
  z-index: 1000 !important;
  -webkit-border-radius: 0px !important;
}

.esriPopup .titlePane {
  background-color: #1565C0 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
}

.esriPopup .contentPane {
  background-color: #FFF !important;
  color: #000 !important;
}

.esriPopup .contentPane .popupRunupDataText {
  font-size: 11px;
}

.esriPopup a {
  color: #fff !important;
}

.actionsPane {
  background-color: #7dbd59 !important;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
}

.pointer
  &.top
  &.topLeft
  &.topRight
    background-color: #1565C0 !important;
  &.bottom
  &.bottomLeft
  &.bottomRight
    background-color: #7dbd59 !important;


.card-content
  padding 5px 20px !important;
